/* global PubSub app $ PNotify process Rollbar */

import { each, defer, isObject, keys, merge, toLower, trim } from 'lodash'
import { matchPath } from 'react-router-dom'
import moment from 'moment'
import { create } from 'apisauce'

import I18n from 'src/i18n'
import Cookies from '../vendor/js.cookie'
import { getClosestNodeParentId, Tree } from '../types'

const api = create({
  baseURL: '/',
  headers: { 'Content-Type': 'application/json' },
})

export function getCurrentUserId() {
  return app.me.id
}

export function getCurrentUserApiKey() {
  return localStorage.getItem('currentUser')
}

export function getApiHeaders() {
  let headers = { 'Content-Type': 'application/json' }

  if (!sso()) {
    headers['Authorization'] = 'Bearer ' + getCurrentUserApiKey()
  }

  return headers
}

export function guid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r, v

    if (window.crypto) {
      r = crypto.getRandomValues(new Uint8Array(1))[0] % 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
    } else {
      r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
    }

    return v.toString(16)
  })
}

export function sso() {
  return window.ssoEnabled && app.me.authSourceId
}

// HTTP fetch helpers

function buildHeaders() {
  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }

  if (!sso()) {
    headers['Authorization'] = 'Bearer ' + getCurrentUserApiKey()
  }

  return headers
}

export class HttpError extends Error {
  constructor(response) {
    super(`${response.status} for ${response.url}`)
    this.name = 'HttpError'
    this.status = response.status
    this.response = response
  }
}

export function processResponse(response) {
  if (response.status >= 200 && response.status < 300) {
    /**
     * This catch is for API compatibility. Sometimes also successful API responses does not have body,
     * e.q. `memberships#create` returns only `HEAD 201 Created`
     *
     * 204 Responses should be always w/o body, it's `HEAD 204 No Content`
     */
    return response.status == 204 ? response : response.json().catch(() => response)
  }

  throw new HttpError(response)
}

function catch401(error) {
  if (error.status == 401) {
    PubSub.publish('accounts/UnauthorizedApiCall', error.reason)

    return true
  }

  return false
}

// catch 403 errors, but only on writes (on reads they are natural)
function catch403(error) {
  if (error.status == 403) {
    flash(I18n.t('messages.errors.forbidden'), 'error')

    return true
  }

  return false
}

function catchOther(error) {
  if (error.reason instanceof HttpError) {
    // notifyTracker('Unhandled', error)
  } else {
    if (window.location.host !== 'digitaal.projektove.cz') {
      notifyTracker('Unhandled Rejection', error)
    }
  }
}

window.addEventListener('unhandledrejection', error => {
  if (isDev()) {
    catch401(error.reason) || catch403(error.reason)
  } else {
    error.preventDefault()
    catch401(error.reason) || catch403(error.reason) // || catchOther(error)
  }
})

export function httpGet(url, headers = null, options = {}) {
  return fetch(url, { credentials: options.withSession || sso() ? 'same-origin' : 'omit', headers: headers || buildHeaders() })
    .then(processResponse)

  // return api.get(
  //   url,
  //   {},
  //   {
  //     headers: headers || buildHeaders(),
  //     withCredentials: false
  //     // withCredentials: options.withSession || sso()
  //   }).then(res => {
  //   if (res.ok) {
  //     return res.data
  //   } else {
  //     if (res.problem !== 'NETWORK_ERROR') {
  //       Sentry.captureException(res.originalError)
  //     }
  //   }

  //   return res
  // })
}

export function httpGetWithRetries(url, noRetries, headers = null, options = {}) {
  return fetch(url, { credentials: options.withSession || sso() ? 'same-origin' : 'omit', headers: headers || buildHeaders() })
    .then(response => {

      if (response.status >= 200 && response.status < 300) {
        /**
         * This catch is for API compatibility. Sometimes also successful API responses does not have body,
         * e.q. `memberships#create` returns only `HEAD 201 Created`
         *
         * 204 Responses should be always w/o body, it's `HEAD 204 No Content`
         */
        return response.status == 204 ? response : response.json().catch(() => response)
      }

      if (noRetries > 0 && response.status > 404) {
        return httpGetWithRetries(url, noRetries - 1, headers, options)
      } else {
        throw new HttpError(response)
      }
    });
}

export function httpHead(url) {
  return fetch(url, { method: 'head' }).then(processResponse)
}

export function httpPost(url, data, headers = null, formData = null) {
  const body = formData || JSON.stringify(data)

  return fetch(url, { method: 'post', headers: headers || buildHeaders(), body: body }).then(processResponse)
}

export function httpPut(url, data, headers = null, formData = null) {
  const body = formData || JSON.stringify(data)

  return fetch(url, { method: 'put', headers: headers || buildHeaders(), body: body }).then(processResponse)
}

export function httpDelete(url, headers = null) {
  return fetch(url, { method: 'delete', headers: headers || buildHeaders() }).then(processResponse)
}

export function toArgs(json, parent = '') {
  if (typeof json !== 'object') {
    if (app.env[0] === 'd') {
      console.log('"json" is not a JSON object') // eslint-disable-line no-console
      return null
    }
  }

  const _keys = keys(json)
  const u = encodeURIComponent

  let urljson = ''

  for (let i = 0; i < _keys.length; i++) {
    let k = parent ? parent + '[' + _keys[i] + ']' : _keys[i]

    if (typeof json[_keys[i]] !== 'object') {
      urljson += u(k) + '=' + u(json[_keys[i]])
    } else {
      urljson += toArgs(json[_keys[i]], k)
    }

    if (i < (_keys.length - 1)) {
      urljson += '&'
    }
  }

  return urljson
}

export function buildProjectAndTasksTree(tasks, projects) {
  const projectsByLft = projects.sortBy(n => n.lft)
  const pLookup = projects.reduce((a, e) => {
    a[e.id] = { id: e.id, children: new Tree(), source: e }

    return a
  }, {})
  const tLookup = tasks.reduce((a, e) => {
    a[e.id] = { id: e.id, children: new Tree(), source: e }

    return a
  }, {})

  let tRoots = new Tree()
  let pRoots = new Tree()

  tasks.forEach(node => {
    let ourNode = tLookup[node.id]
    let parentNode = tLookup[node.parentId]

    if (!parentNode) {
      // there is not visible parent or visible parent not in lookup table, add this one as root node
      tRoots.push(ourNode)
    } else {
      parentNode.children.push(ourNode)
    }
  })

  projects.forEach(node => {
    let ourNode = pLookup[node.id]
    let parentNode = pLookup[getClosestNodeParentId(projectsByLft, node)]

    if (!parentNode) {
      // there is not visible parent or visible parent not in lookup table, add this one as root node
      pRoots.push(ourNode)
    } else {
      parentNode.children.push(ourNode)
    }
  })

  tRoots.forEach(node => pLookup[node.source.projectId] && pLookup[node.source.projectId].children.push(node))

  return pRoots
}

const RE_ASCII = /^[\w-.\s,()]*$/

export function strComparator(a, b, direction = true) {
  a = toLower(trim(a))
  b = toLower(trim(b))

  if (RE_ASCII.test(a) && RE_ASCII.test(b)) {
    // both sides contains only ASCII characters so use fast comparison (using operator)
    return [true, 1, '1', 'asc', 'ASC'].includes(direction)
      ? a > b ? 1 : a < b ? -1 : 0
      : a < b ? 1 : a > b ? -1 : 0
  } else {
    // there are special characters so we need to use localCompare (which is really slow BTW)
    let res = a.localeCompare(b, I18n.locale || window.app.locale)

    return !direction && res !== 0 ? res * -1 : res
  }
}

export function comparator(a, b, direction = true) {
  if (typeof a === 'string' || typeof b === 'string') {
    return strComparator(a || '', b || '', [true, 1, '1', 'asc', 'ASC'].includes(direction))
  }

  if ([true, 1, '1', 'asc', 'ASC'].includes(direction)) {
    if (a instanceof Date || b instanceof Date) {
      a = moment(a).valueOf()
      b = moment(b).valueOf()
    }
    // ASC
    return a > b ? 1 : a < b ? -1 : 0
  } else {
    // DESC
    return a < b ? 1 : a > b ? -1 : 0
  }
}

// this is a mixin, use it with .bind(this) in React components
export function savedMixin(state = {}) {
  this.setState(merge(state, { icon: 'p-done' }), () =>
    setTimeout(() => this.setState({ icon: null }), 1000))
}

export function forgottAsyncActionOnSuccess(action, cb, after = 1000) {
  if (action && action.state === 'success') {
    setTimeout(() => cb(action.entityId, {}, { actionId: action.actionId, stage: 'forgott' }), after)

    return true
  }

  return false
}

export function getInstances() {
  let instances = Cookies.getJSON('instances')

  if (instances == void 0) {
    instances = {}
  }

  return instances
}

export function storeInstances(value) {
  Cookies.set('instances', value, {
    expires: 180,
    path: '/',
    domain: `.${location.host.split(':')[0].split('.').slice(1).join('.')}`,
    secure: true,
  })
}

const LS_VERSION = '1.3.0'

export function getLocalStorage(key, version = LS_VERSION) {
  let value

  try {
    value = localStorage.getItem(key)

    if (value) {
      value = parseValueFromLocalStorage(value, version)
    }
  } catch (e) {
    if (process.env.NODE_ENV[0] === 'p') {
      removeLocalStorage(key)
    } else {
      throw e
    }
  }

  return value
}

function parseValueFromLocalStorage(value, version) {
  value = JSON.parse(value)

  if (value instanceof Array) {
    if (value.length === 3 && value[0] === 'json') {
      value = value[1] == version ? JSON.parse(value[2]) : null
    }
  }

  return value
}

export function setLocalStorage(key, value, version = LS_VERSION, options = {}) {
  function _setLocalStorage() {
    let data

    if (value instanceof Array) {
      data = JSON.stringify(value)
    } else if (value instanceof Object) {
      data = JSON.stringify(['json', version, JSON.stringify(value)])
    } else {
      data = JSON.stringify(value)
    }

    try {
      localStorage.removeItem(key)
      localStorage.setItem(key, data)
    } catch (e) {
      if (process.env.NODE_ENV[0] !== 'p') {
        throw e
      }
    }
  }

  options.skipDelay ? _setLocalStorage() : defer(_setLocalStorage)
}

export function listLocalStorage() {
  try {
    return keys(localStorage)
  } catch (e) {
    if (process.env.NODE_ENV !== 'production') {
      throw e
    }
  }
}

export function removeLocalStorage(key) {
  setTimeout(() => {
    try {
      localStorage.removeItem(key)
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        throw e
      }
    }
  }, 0)
}

export function getHash(hashName) {
  const hashes = window.location.hash.replace('#', '')
  const allHash = hashes.split(';')

  let splitAllHash = []
  let myHash = []

  allHash.forEach(p => {
    splitAllHash = p.split('=')

    if (splitAllHash[0] === hashName && hashName === 'f') {
      myHash = splitAllHash[1].split(',')
    } else if (splitAllHash[0] === hashName) {
      myHash = splitAllHash[1]
    }
  })

  return myHash.length > 0 ? myHash : null
}

export function diffForChanges(obj, changes) {
  let r = {}

  each(changes, (v, k) => {
    if (obj[k] === v || obj[k] === void 0) {
      return
    }

    r[k] = isObject(v) ? diffForChanges(obj[k], v) : obj[k]
  })

  return r
}

export function notifyTracker(msg, data = null) {
  if (window.Rollbar !== void 0) {
    if (data?.reason) {
      msg = `${msg}: ${data.reason.toString()}`
    }

    Rollbar.error(msg)
  } else {
    if (isDev()) {
      throw data || new Error(msg)
    }
  }
}

export function getMindMapCursorLocation(event) {
  let doc = document.documentElement
  let canvas = document.getElementById('MindMapCanvas')
  let offsetY = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
  let pageMarginLeft = document.body.clientWidth < 768 ? 50 : 170
  let pageMarginTop = document.body.clientWidth < 768 ? 350 : 200
  let x = event.clientX - pageMarginLeft + canvas.scrollLeft
  let y = Math.ceil(((event.clientY + offsetY + canvas.scrollTop - pageMarginTop) - 25) / 25) * 25 + 25 // get Y and round it to 25, 50, 75, 100 grid

  return [x, y]
}

export function flash(message, type = 'success', opts = {}) {
  let { title = null, hide = true, closer = (type == 'error'), delay = (['error', 'warn'].includes(type) ? 7500 : 2500), ...rest } = opts

  if (typeof message === 'object') {
    title = message[0]
    message = message[1]
  }

  let args = {
    ...rest,
    type,
    text: message,
    // eslint-disable-next-line camelcase
    animate_speed: 'fast',
    icon: false,
    delay,
    buttons: { closer, sticker: false, labels: { close: I18n.t('shared.pnotify.close_title') } },
    addclass: type,
    hide,
  }

  if (title) {
    merge(args, { title })
  }

  return new PNotify(args)
}
window.flash = flash

$.extend({
  pnotify: function (args) {
    if (typeof args == 'string') {
      flash(args)
    } else {
      flash(args.text, args.type, args)
    }
  }
})

/**
 * Returns a random number between min (inclusive) and max (exclusive)
 */
export function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive)
 * Using Math.round() will give you a non-uniform distribution!
 */
export function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export function isMacOs() {
  return (window.navigator.userAgent.indexOf('Mac') != -1) ? true : false
}

export function getProjectIdFromPathname(pathname) {
  const match = matchPath(pathname, '/projects/:projectId')

  return match ? parseInt(match.params.projectId) : null
}

export function moveCursorOnValueEnd(ev) {
  let val = ev.target.value
  ev.target.value = ''
  ev.target.value = val
}

export function isDev() {
  return app.env === 'development'
}

export function isOnPremise() {
  return !window.location.host.includes('.projektove.cz')
}

export function getNonWorkingWeekDays() {
  return [6, 7]
}

// lib redmine utils DateCalculation analogy
export function dateDurationDays(startDate, dueDate) {
  startDate = moment(startDate).startOf('day')
  dueDate = moment(dueDate).endOf('day')
  let diff = moment.duration(dueDate.diff(startDate))
  return Math.ceil(diff.as('days'))
}
export function workingDays(from, to) {
  if (from > to) {
    let tmp = from
    from = to
    to = tmp
  }
  let days = dateDurationDays(from, to)
  const nonWorkingWeekDays = getNonWorkingWeekDays()
  if (days > 0) {
    let weeks = Math.floor(days / 7)
    let result = weeks * (7 - nonWorkingWeekDays.length)
    let daysLeft = days - weeks * 7
    let startCwday = moment(from).isoWeekday()
    for (let i = 0; i < daysLeft; i++) {
      if (!nonWorkingWeekDays.includes(((startCwday + i - 1) % 7) + 1)) {
        result += 1
      }
    }
    return result
  } else {
    return 0
  }
}
export function addWorkingDays(date, workingDays) {
  if (workingDays > 0) {
    date = moment(date)
    const nonWorkingWeekDays = getNonWorkingWeekDays()
    workingDays -= 1 // use first day as full working day
    let weeks = Math.floor(workingDays / (7 - nonWorkingWeekDays.length))
    let result = weeks * 7
    let daysLeft = workingDays - weeks * (7 - nonWorkingWeekDays.length)
    let cwday = moment(date).isoWeekday()
    while (daysLeft > 0) {
      cwday += 1
      if (!nonWorkingWeekDays.includes(((cwday - 1) % 7) + 1)) {
        daysLeft -= 1
      }
      result += 1
    }
    return nextWorkingDate(date.add(result, 'day'))
  } else {
    return subtractWorkingDays(date, Math.abs(workingDays))
  }
}

export function nextWorkingDate(date) {
  date = moment(date)
  const nonWorkingWeekDays = getNonWorkingWeekDays()
  let cwday = date.isoWeekday()
  let days = 0
  while (nonWorkingWeekDays.includes(((cwday + days - 1) % 7) + 1)) {
    days += 1
  }
  return date.add(days, 'day')
}

export function isMVCR() {
  return window.location.hostname === 'projektove' && window.app.company.companyId === 6293
}
